import React, { memo, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Icon from 'modules/Icon';
import { separator, verticalLine } from 'assets/svg';
import { PRODUCT_EMAIL, VIMEO_LINK, INSTAGRAM_LINK } from 'core/constants';
import * as styles from './ThankYouPage.module.scss';
import { navigate } from 'gatsby';

function ThankYouPage() {
  const { t } = useTranslation();

  setTimeout(() => {
    navigate('/');
  }, 15000);

  return (
    <div className={styles.container}>
      <img
        className={styles.container__image}
        src={'/mainPageImg/thankYou.png'}
        alt={t('layout:footer.image')}
      />
      <div className={styles.container__content}>
        <h1 className={styles.container__content__heading}>
          {t('workWithUs:thankYou')}
        </h1>
        <div className={styles.container__content__footer}>
          <div className={styles.container__content__footer__text}>
            <p
              className={styles.container__content__footer__text__description}
              dangerouslySetInnerHTML={{
                __html: t('workWithUs:description'),
              }}
            />
          </div>
          <Icon
            path={verticalLine.path}
            viewBox={verticalLine.viewBox}
            className={styles.container__content__footer__icon}
          />
          <div className={styles.container__content__footer__media}>
            <a
              className={styles.container__content__footer__media__link}
              href={`mailto:${PRODUCT_EMAIL}`}
            >
              production@elandic.ru
            </a>
            <a
              target="_blank"
              className={styles.container__content__footer__media__link}
              href={INSTAGRAM_LINK}
              rel="noreferrer"
            >
              @elandic.tv
            </a>
            <a
              target="_blank"
              className={styles.container__content__footer__media__link}
              href={VIMEO_LINK}
              rel="noreferrer"
            >
              vimeo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ThankYouPage);
