// extracted by mini-css-extract-plugin
export var container = "ThankYouPage-module--container--bB4Wp";
export var container__content = "ThankYouPage-module--container__content--UF8yk";
export var container__content__footer = "ThankYouPage-module--container__content__footer--0kYBy";
export var container__content__footer__icon = "ThankYouPage-module--container__content__footer__icon--MVJP3";
export var container__content__footer__media = "ThankYouPage-module--container__content__footer__media--aHnMX";
export var container__content__footer__media__link = "ThankYouPage-module--container__content__footer__media__link--NTvXj";
export var container__content__footer__text = "ThankYouPage-module--container__content__footer__text--Y3K38";
export var container__content__footer__text__description = "ThankYouPage-module--container__content__footer__text__description--OzLDl";
export var container__content__heading = "ThankYouPage-module--container__content__heading--O1AM9";
export var container__image = "ThankYouPage-module--container__image--1EMlf";